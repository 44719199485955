<template>
  <v-card elevation="2" :disabled="disabled" :loading="loading">
    <v-card-title primary-title>
      <div>
        <div class="headline">จุดตรวจ</div>
        <span class="grey--text subtitle-2">ข้อมูลจุดตรวจในคำสั่ง</span>
      </div>
      <v-spacer></v-spacer>
      <div v-if="can.update">
        <v-btn color="blue darken-3" :disabled="disabledAll" @click="addNewCheckpoint" class="mr-2 white--text">
          <v-icon>
            mdi-traffic-cone
          </v-icon>
          เพิ่มจุดตรวจ
        </v-btn>
        <v-btn color="blue darken-3" :disabled="disabledAll" class="mr-2 white--text" @click="showCheckpointList">
          <v-icon>
            mdi-content-copy
          </v-icon>
          คัดลอกจากที่มีอยู่
        </v-btn>
        <v-btn
          color="red darken-3"
          class="white--text"
          @click="multipleDelete"
          :disabled="!showMultipleDelete || disabledAll"
        >
          <v-icon>
            mdi-delete
          </v-icon>
          ลบรายการที่เลือก
        </v-btn>
      </div>
    </v-card-title>
    <checkpoint-table
      ref="cpTable"
      :command-id="commandId"
      :disabled="disabledAll"
      @selectedItem="onItemSelected"
      @row-dbl-click="rowDblClick"
      @logs="logs"
    ></checkpoint-table>
    <dialog-form
      ref="cpDialog"
      v-model="dialog"
      :allowDates="allowDates"
      :type="type"
      @cancelClick="cancelDialog"
      @submit="onSubmit"
    ></dialog-form>
    <list-dialog ref="listDlg" v-model="listDialog" @duplicated="onDuplicated" @notDuplicate="onDuplicateError">
    </list-dialog>
    <log-dlg ref="logdlg" v-model="logDialog"></log-dlg>
    <bjp-confirm-dialog ref="confirm"></bjp-confirm-dialog>
  </v-card>
</template>

<script>
import checkpointTable from './checkpointTable'
import dialogForm from './dialogForm'
import listDialog from './listDialog'
import logDlg from '@/components/activityList'
import { multiDelete } from '@/api/checkpoint'
export default {
  methods: {
    rowDblClick(data) {
      this.$refs.cpDialog.initCheckpoint(data, 'edit')
      this.dialog = true
      this.type = 'edit'
    },
    addNewCheckpoint() {
      this.$refs.cpDialog.initCheckpoint(null, 'add')
      this.dialog = true
      this.type = 'add'
    },
    onSubmit(v) {
      this.type = 'add'
      this.$refs.cpTable.getList(this.$route.params.id)
      this.dialog = false
    },
    cancelDialog() {
      this.type = 'add'
      this.$refs.cpTable.getList(this.$route.params.id)
      this.dialog = false
    },
    logs(id) {
      this.$refs.logdlg.getLogs('checkpoint', id)
      this.logDialog = true
    },
    showCheckpointList() {
      this.listDialog = true
    },
    async multipleDelete() {
      if (await this.$refs.confirm.open('ยืนยัน', 'คุณกำลังจะลบจุดตรวจหลายรายการ กรุณายืนยัน', { color: 'red' })) {
        this.loading = true
        multiDelete({ ids: this.selectedItem })
          .then(res => {
            this.$toast.success({
              title: 'Info',
              message: 'ทำรายการเรียบร้อยแล้ว',
            })
            this.showMultipleDelete = false
            this.$refs.cpTable.getList(this.$route.params.id)
          })
          .finally(() => {
            this.showMultipleDelete = false
            this.loading = false
          })
      }
    },
    onDuplicated() {
      this.$refs.cpTable.getList(this.$route.params.id)
      this.listDialog = false

      this.$toast.success({
        title: 'Information',
        message: 'ทำการคัดลอกสำเร็จแล้ว',
      })
    },
    onDuplicateError() {
      this.listDialog = false
      this.$toast.error({ message: 'เกิดความผิดพลาดระหว่างทำรายการ' })
    },
    onItemSelected(v) {
      if (v.length > 0) {
        this.selectedItem = v.map(i => i.id)
        this.showMultipleDelete = true
      } else {
        this.showMultipleDelete = false
      }
    },
  },
  data() {
    return {
      show: false,
      dialog: false,
      listDialog: false,
      logDialog: false,
      loading: false,
      type: 'add',
      selectedItem: [],
      showMultipleDelete: false,
    }
  },
  components: {
    checkpointTable,
    dialogForm,
    listDialog,
    logDlg,
  },
  props: {
    can: {
      type: Object,
      default() {
        return {
          update: false,
          open: false,
          delete: false,
        }
      },
    },
    disabledAll: {
      type: Boolean,
      default: false,
    },
    commandId: {},
    disabled: {},
    allowDates: {},
  },
}
</script>

<style></style>
