<template>
  <div>
    <v-data-table
      :headers="headers"
      v-model="itemSelected"
      :loading="loading"
      :items="lists"
      :options.sync="options"
      :items-per-page.sync="tableMeta.per_page"
      :server-items-length="tableMeta.total"
      :footer-props="footer"
      :disabled="disabled"
      show-select
      class="elevation-1 row-pointer"
      @dblclick:row="rowDblClick"
    >
      <template v-slot:[`item.start_at`]="{ item }">
        {{ thDate(item.start_at) }}
      </template>
      <template v-slot:[`item.end_at`]="{ item }">
        {{ thDate(item.end_at) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-speed-dial direction="left" open-on-hover>
          <v-btn color="blue darken-4" icon slot="activator" x-small dark fab>
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                color="primary"
                @click="rowDblClick(null, { item: item })"
                v-on="on"
                v-bind="attrs"
                :disabled="disabled"
              >
                <v-icon small color="white">mdi-pen</v-icon>
              </v-btn>
            </template>
            <span>แก้ไขจุดตรวจ</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small fab color="grey" @click="logs(item.id)" v-on="on" v-bind="attrs">
                <v-icon small color="white">mdi-playlist-check</v-icon>
              </v-btn>
            </template>
            <span>Change Logs</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                v-if="item.can.delete"
                :disabled="disabled"
                @click="del(item.id) || !disabled"
                color="red"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon small color="white">mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>ลบจุดตรวจ</span>
          </v-tooltip>
        </v-speed-dial>
      </template>
    </v-data-table>
    <bjp-confirm-dialog ref="confirm"></bjp-confirm-dialog>
  </div>
</template>

<script>
import { list, destroy } from '@/api/checkpoint'
export default {
  methods: {
    getList(id) {
      // console.log('get cp list')
      if (id == null || id == '') return
      this.loading = true
      const { page, itemsPerPage } = this.options
      const param = {
        page: page,
        limit: itemsPerPage,
        keyword: this.keyword,
      }
      list(this.commandId, param)
        .then(res => {
          this.lists = res.data
          let meta = res.meta
          let itemsPerPage = parseInt(meta.per_page)
          this.tableMeta = {
            itemsPerPage: itemsPerPage,
            per_page: itemsPerPage,
            page: meta.current_page,
            total: meta.total,
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    rowDblClick(e, data) {
      if (this.disabled) return
      // console.log(data.item)
      this.$emit('row-dbl-click', data.item)
    },
    // utils
    thDate(d) {
      const date = this.moment(d)
          .format('D MMM')
      const year = this.moment(d)
      .add(543, 'year')
          .format('YYYY HH:mm')
      return (
        `${date}${year}` || null
      )
      // return this.moment(d)
      //   .add(543, 'year')
      //   .format('D MMMYYYY HH:mm')
    },
    logs(id) {
      // console.log(id)
      this.$emit('logs', id)
    },
    async del(id) {
      if (await this.$refs.confirm.open('ยืนยัน', 'คุณต้องการจะลบรายการนี้ ?', { color: 'red' }).agree) {
        destroy(this.$route.params.id, id).then(res => {
          if (res.success) {
            this.getList(this.$route.params.id)
            this.$toast.success({
              title: 'info',
              message: 'ลบข้อมูลเรียบร้อยแล้ว',
            })
          }
        })
      }
    },
  },
  props: {
    keyword: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    commandId: {},
    disabled: {},
  },
  watch: {
    options: {
      handler() {
        this.getList(this.commandId)
      },
      deep: true,
    },
    commandId(v) {
      this.getList(v)
    },
    itemSelected(v) {
      this.$emit('selectedItem', v)
    },
  },
  created() {
    // this.getList(this.commandId)
  },
  data() {
    return {
      itemSelected: [],
      loading: false,
      headers: [
        {
          text: 'จุดตรวจ',
          sortable: false,
          value: 'name',
        },
        {
          text: 'สถานที่',
          sortable: false,
          value: 'address',
        },
        {
          text: 'เริ่ม',
          sortable: false,
          value: 'start_at',
        },
        { text: 'ถึง', value: 'end_at', sortable: false },
        {
          text: 'หัวหน้าจุดตรวจ',
          value: 'chief',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      footer: {
        itemsPerPageOptions: [10, 50, 100, 200],
        showCurrentPage: true,
        showFirstLastPage: true,
      },
      tableMeta: {
        itemsPerPage: 10,
        per_page: 10,
        page: 1,
        total: 0,
      },
      lists: [],
      options: {},
    }
  },
  computed: {},
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
