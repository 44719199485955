<template>
  <v-container fluid>
    <v-toolbar dense rounded flat outlined class="mb-4">
      <v-breadcrumbs divider=">" :items="$route.meta.breadcrumbs"> </v-breadcrumbs>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <!-- ถ้ายังไม่มีการขออนุมัติ -->
        <v-btn
          v-show="false"
          v-if="command.approval != 0"
          text
          color="orange darken-1"
          @click="approvalRequest"
          :disabled="commandId == '' || !command.can.update"
        >
          <v-icon>mdi-check</v-icon>
          ขออนุมัติคำสั่ง <span v-if="command.approval == 1">(อีกครั้ง)</span>
        </v-btn>
        <!-- ถ้าขออนุมัติแล้ว -->
        <v-btn
          v-else-if="command.approval == 0"
          text
          color="red darken-1"
          @click="cancelRequest"
          :disabled="commandId == '' || !command.can.update"
        >
          <v-icon>mdi-cancel</v-icon>
          ยกเลิกคำขออนุมัติ
        </v-btn>
        <v-btn text @click="showPdf" :disabled="commandId == ''">
          <v-icon>mdi-printer</v-icon>
          พิมพ์คำสั่ง
        </v-btn>
        <v-btn text @click="loadDocx" :disabled="commandId == ''">
          <v-icon>mdi-microsoft-word</v-icon>
          MS-WORD
        </v-btn>
        <v-btn text @click="duplicateCommand" :disabled="commandId == '' || !command.can.update">
          <v-icon>mdi-content-duplicate</v-icon>
          ทำซ้ำคำสั่ง
        </v-btn>
        <v-btn
          text
          color="red"
          @click="deleteCommand"
          :disabled="commandId == '' || !command.can.delete || command.approval == 0"
        >
          <v-icon>mdi-delete</v-icon>
          ลบคำสั่ง
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-toolbar v-if="command.approval != 3" dense rounded flat outlined class="mb-4">
      <span v-if="command.approval == 2">
        <v-icon color="red">mdi-cancel</v-icon>
        ไม่อนุมัติ เนื่องมาจาก <span class="red--text">{{ command.approval_detail.reason }}</span>
      </span>
      <span v-if="command.approval == 1">
        <v-icon color="green">mdi-check</v-icon>
        อนุมัติคำสั่งแล้ว
      </span>
      <span v-if="command.approval == 0">
        <v-icon color="blue">mdi-timelapse</v-icon>
        รอการอนุมัติ
      </span>
    </v-toolbar>
    <v-row>
      <v-col cols="12" md="4" lg="3">
        <v-card :loading="loading">
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">
                ข้อมูลคำสั่ง
                <span class="grey--text subtitle-2">
                  {{ type == 'edit' ? '(แก้ไข)' : '(เพิ่ม)' }}
                </span>
              </h3>
              <small>กรอกรายละเอียดให้ครบถ้วน</small>
            </div>
          </v-card-title>
          <v-container fluid>
            <cmd-form ref="cmdForm" :type="type" v-model="command" :valid.sync="valid"></cmd-form>
          </v-container>
          <v-card-actions>
            <v-btn
              @click="submit"
              :disabled="!valid || !command.can.update || command.approval == 0"
              color="blue darken-4"
              class="white--text"
            >
              <v-icon small>
                mdi-content-save
              </v-icon>
              บันทึกข้อมูล
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" lg="9">
        <v-expand-transition>
          <checkpoint-list
            :command-id="commandId"
            :disabled="commandId == ''"
            :allowDates="[command.start_at, command.end_at]"
            :can="command.can"
            :disabled-all="command.approval == 0"
          ></checkpoint-list>
        </v-expand-transition>
      </v-col>
    </v-row>
    <!-- Snack bar -->
    <v-snackbar :timeout="2000" v-model="showAlert" absolute centered :color="snackColor">
      {{ message }}
    </v-snackbar>

    <!-- confirm dlg -->
    <bjp-confirm-dialog ref="confirm" />

    <!-- pdf dialog -->
    <pdf-dialog ref="pdf"></pdf-dialog>
  </v-container>
</template>

<script>
import cmdForm from './form'
import { show, store, update, duplicate, destroy, getPdf, getDocx } from '@/api/command'

import { store as approvalReq, destroy as cancelReq } from '@/api/command_approval'
// components
import checkpointList from '../checkpoint'
import pdfDialog from '@/components/pdfDialog'
export default {
  name: 'CommandDetailPage',
  methods: {
    submit() {
      if (this.$refs.cmdForm.validate()) {
        this.loading = true
        // data
        const data = this.command

        if (this.type == 'edit') {
          update(data)
            .then(res => {
              this.command = Object.assign({}, res)
              this.showMessage('บันทึกการแก้ไขข้อมูลเรียบร้อย')
            })
            .catch(err => {
              this.showMessage(err, 'error')
            })
            .finally(() => {
              this.loading = false
            })
          return
        }
        // call api
        store(data)
          .then(res => {
            this.commandId = res.id
            this.command = Object.assign({}, res)
            this.$router.replace({ params: { id: res.id } })
            this.type = 'edit'
            this.showMessage('บันทึกข้อมูลเรียบร้อย เพิ่มข้อมูลจุดตรวจได้เลย')
          })
          .catch(err => {
            this.showMessage(err, 'error')
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    clear() {
      this.$refs.cmdForm.reset()
    },
    getCommand(id) {
      this.loading = true
      show(id)
        .then(res => {
          this.command = Object.assign({}, res)
          this.commandId = res.id
          // this.$refs.cmdForm.setCommand(this.command)
        })
        .catch(err => {
          console.log(err.response)
          if (err.response.status == 403) {
            this.$router.push({ name: 'Command' })
            this.$toast.error({
              title: 'Error',
              message: 'คุณไม่มีสิทธิ์ในการดูคำสั่งนี้',
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    duplicateCommand() {
      this.loading = true
      duplicate(this.commandId)
        .then(res => {
          if (res.success) {
            this.showMessage('ทำรายการเรียบร้อยแล้ว')
            setTimeout(() => {
              this.$router.push({ name: 'Command' })
            }, 3000)
          }
        })
        .catch(err => {
          this.showMessage('ไม่สามารถทำรายการได้', 'red')
        })
        .finally(() => {
          this.loading = false
        })
    },
    async approvalRequest() {
      let dlg = await this.$refs.confirm.open(
        'ทำรายการขออนุมัติ',
        'การกดขออนุมัติ แสดงว่าท่านได้ทำการตรวจสอบความซ้ำซ้อนในการตั้งจุดตรวจ ตรวจสอบความถูกต้องเรียบร้อย และได้รับอนุญาตจากหัวหน้าสถานี/หน่วยงานของท่านแล้ว กรุณายืนยันการขออนุมัติคำสั่งนี้ '
      )
      if (dlg) {
        this.loading = true
        let data = {
          plan_id: this.commandId,
          remark: '',
        }
        approvalReq(data)
          .then(res => {
            this.showMessage('ทำรายการเรียบร้อยแล้ว')
            setTimeout(() => {
              this.$router.push({ name: 'Command' })
            }, 3000)
          })
          .catch(err => {
            this.showMessage('ไม่สามารถทำรายการได้', 'red')
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    async cancelRequest() {
      this.loading = true
      cancelReq(this.command.approval_detail.id)
        .then(res => {
          this.showMessage('ทำรายการเรียบร้อยแล้ว')
          setTimeout(() => {
            this.$router.push({ name: 'Command' })
          }, 3000)
        })
        .catch(err => {
          this.showMessage('ไม่สามารถทำรายการได้', 'red')
        })
        .finally(() => {
          this.loading = false
        })
    },
    async deleteCommand() {
      let dlg = await this.$refs.confirm.open('ลบรายการ', 'คุณต้องการลบรายการนี้ ?')
      if (dlg) {
        this.loading = true
        destroy(this.commandId)
          .then(res => {
            this.showMessage('ทำรายการเรียบร้อยแล้ว')
            setTimeout(() => {
              this.$router.push({ name: 'Command' })
            }, 3000)
          })
          .catch(err => {
            this.showMessage('ไม่สามารถทำรายการได้', 'red')
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    // utils
    getToday() {
      return new Date().toISOString().substr(0, 10)
    },
    getPreRange(d) {
      const s = this.moment(d)
        .add(1, 'month')
        .startOf('month')
        .format('YYYY-MM-DD')
      const e = this.moment(d)
        .add(1, 'month')
        .endOf('month')
        .format('YYYY-MM-DD')
      return [s, e]
    },
    showMessage(msg, color) {
      if (color == undefined) color = 'primary'
      this.snackColor = color
      this.message = msg
      this.showAlert = true
    },
    async showPdf() {
      const pdfBase64 = await getPdf(this.$route.params.id)
      this.$refs.pdf.open(pdfBase64.pdf)
    },
    async loadDocx() {
      const docx = await getDocx(this.$route.params.id)
      const blob = this.convertToBlob(docx.file)
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      const title = `คำสั่ง_${this.command.station.name_abbr.replace(
        /(\.|\s)/g,
        '_'
      )}${this.command.plan_number.replace('/', '_')}`
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    convertToBlob(base64) {
      var binary_string = window.atob(base64)
      var len = binary_string.length
      var bytes = new Uint8Array(len)
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
      }
      return new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
    },
  },

  data() {
    return {
      type: 'add',
      valid: true,
      loading: false,
      // snackbar
      showAlert: false,
      snackColor: 'primary',
      message: '',
      // data
      commandId: '',
      command: {
        approval_detail: {
          reason: '',
        },
        can: {
          update: false,
          delete: false,
          open: false,
        },
      },
      commandBlank: {
        name: '',
        plan_number: '',
        plan_date: this.getToday(),
        dates: this.getPreRange(this.getToday()),
        objective: '',
        owner_id: '',
        remark: '',
        can: {
          update: true,
          delete: true,
          open: true,
        },
      },
    }
  },
  created() {
    const id = this.$route.params.id

    if (/\d+/.test(id)) {
      // console.log(id)
      this.type = 'edit'
      this.getCommand(id)
    } else {
      this.type = 'add'
      this.command = Object.assign({}, this.commandBlank)
    }
  },
  watch: {
    'command.plan_date'(v) {
      if (this.type == 'edit') return
      this.command.dates = this.getPreRange(v)
    },
  },
  computed: {},
  components: {
    checkpointList,
    cmdForm,
    pdfDialog,
  },
}
</script>

<style></style>
